<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <div style="width:350px">
                <el-input
                  v-model="searchData.name"
                  placeholder="输入销售合伙人名称/销售合伙人手机号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10 marginBottom10"
              >查询</el-button>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10 marginBottom10"
              >重置</el-button>
            </div>

          </div>
        </div>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          v-if="row.id !== 'a031a43e40ca49c9a0694c9cfcad81f8'"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <div v-else>-</div>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      title="编辑"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >

        <template slot="partnerConsumePrice">
          <el-input
            v-model="form.partnerConsumePrice"
            placeholder="请输入 裂变好友每消费金额(元)"
            type="text"
            onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            @blur="salaryDianChange($event, 'partnerConsumePrice', 0, 999999.99)"
          />
        </template>

        <template slot="partnerGetIntegral">
          <el-input
            v-model="form.partnerGetIntegral"
            placeholder="请输入 销售合伙人获得积分"
            onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
            @blur="salaryChange($event, 'partnerGetIntegral', 999999)"
            type="text"
            clearable
          />
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  atomicFissionList,
  atomicFissionUpdate,
} from "@/api/region/commission";
export default {
  name: "index",
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "好友裂变赠送设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "销售合伙人",
            prop: "partnerUsername",
          },
          {
            label: "销售合伙人手机号",
            prop: "partnerPhone",
          },
          {
            label: "赠送裂变好友优惠券",
            prop: "couponName",
          },
          {
            label: "裂变好友每消费金额（元）",
            prop: "partnerConsumePrice",
          },
          {
            label: "销售合伙人获得积分",
            prop: "partnerGetIntegral",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        type: "",
        partnerConsumePrice: "",
        partnerGetIntegral: "",
      },
      formOption: {
        labelWidth: "200",
        submitText: "确定",
        emptyBtn: false,
        column: [
          {
            label: "裂变好友每消费金额(元)",
            prop: "partnerConsumePrice",
            type: "number",
            span: 24,
            value: 1,
            maxRows: 9.9,
            minRows: 1,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入裂变好友每消费金额",
                trigger: "blur",
              },
            ],
          },
          {
            label: "销售合伙人获得积分",
            prop: "partnerGetIntegral",
            type: "number",
            span: 24,
            value: 1,
            maxRows: 999999,
            minRows: 0,
            controls: false,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入销售合伙人获得积分",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        name: this.searchData.name,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));

      this.showLoading = true;
      atomicFissionList({
        ...searchForm,
        page: this.page.currentPage,
        size: this.page.pageSize,
        id: this.$route.query.id,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 编辑
    async handleEdit(row) {
      this.form = row;
      this.dialogVisible = true;
    },
    // 提交
    submit(form, done) {
      atomicFissionUpdate(form)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            setTimeout(() => {
              this.resetForm();
            }, 500);
            this.onLoad();
          } else {
            this.$message.error(res.msg);
            done();
          }
        })
        .catch(() => done());
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
    salaryDianChange(e, t, min, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            if (value < min) {
              this.form[t] = min.toFixed(2);
              e.target.value = min.toFixed(2);
            } else {
              this.form[t] = parseFloat(value).toFixed(2);
              e.target.value = parseFloat(value).toFixed(2);
            }
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped></style>
